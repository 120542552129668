<template>
  <div class="wrap">
    <nf-breadcrumb :breadList="[{ name: '专家人才' }]"></nf-breadcrumb>
    <div class="expertTalent">
      <el-tabs v-model="active" :tab-position="tabPosition">
        <!-- <el-tab-pane class="title" label="三亚专家人才" disabled></el-tab-pane> -->
        <el-tab-pane label="专家人才榜" name="1"><expert></expert></el-tab-pane>
        <!-- <el-tab-pane label="人才榜" name="2"><talent></talent></el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import expert from '@/pages/expertTalent/expert';
import talent from '@/pages/expertTalent/talent';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'expertTalent',
  data() {
    return {
      startTime: null,
      pageName: "专家人才(expertTalent.vue)",
      active: '1',
      tabPosition: 'left'
    };
  },
  components: {
    expert,
    talent,
    nfBreadcrumb
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
  }
};
</script>

<style lang="less" scoped>
.breadWrap {
  border-bottom: 1px solid #ccc;
  .el-breadcrumb {
    width: 1200px;
    margin: 20px auto;
    font-size: 16px;
  }
}
.expertTalent {
  width: 96%;
  margin: 0 auto;
}
.expertTalent /deep/ .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
.expertTalent /deep/ .el-tabs__item.is-disabled {
  font-size: 25px;
  color: #333;
}
.expertTalent /deep/ .el-tabs__item.is-active {
  background: #f1f1f1;
  border-left: 2px solid #409eff;
}
.expertTalent /deep/ .el-tabs__item {
  font-size: 18px;
  padding-left: 40px;
  margin-bottom: 20px;
  border-left: 2px solid transparent;
}
.expertTalent /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
  width: 0;
}
.expertTalent /deep/ .el-tabs__active-bar {
  width: 0;
}
.expertTalent /deep/ .el-tabs__nav-scroll {
  margin-right: 30px;
}
.expertTalent {
  .el-tabs {
    padding-top: 40px;
  }
}
</style>
