<template>
  <div class="expert">
    <div class="title">全国科技专家人才</div>
    <el-divider></el-divider>
    <div class="describe">
      南繁硅谷云收录了全国科技专家人才，为南繁基地提供现金科创人才资源保障，平台提供人才专家信息，供用户查阅。
    </div>
    <div class="example">专家人才介绍</div>
    <!-- 列表 -->
    <div v-if="list.length">
      <div class="list">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="
            $router.push({
              path: '/expertTalentDetail',
              query: { id: item.id }
            })
          "
        >
          <el-divider></el-divider>
          <div class="imgBox">
            <el-image
                  style="width: 120px; height: 130px"
                  :src="item.photo"
                  fit="contain"></el-image>
          </div>
          <div class="content">
            <div class="name">{{ item.name }}</div>
            <div class="major">专业：{{ item.professionalism }}</div>
            <div class="work">工作单位：{{ item.workUnit }}</div>
          </div>
        </div>
      </div>
      <nf-pagination
        :total="total"
        :currentPage="currentPage"
        @pageChange="pageChange"
      ></nf-pagination>
    </div>
    <div v-else class="noData">
      <img src="@/assets/images/noData.png" alt="" />
      <p>暂无相关数据</p>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import nfPagination from '@/component/pagination/index.vue';
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
  name: 'expert',
  data() {
    return {
      // 请求参数
      params: {
        pageNum: 1,
        pageSize: 5,
        expertType: 1
      },
      currentPage: Number(this.$route.query.expert_pageNum || 1), // 当前页
      total: 0, // 总页数
      list: [] // 列表
    };
  },
  methods: {
    // 获取列表
    async getList() {
      this.params.pageNum = this.$route.query.expert_pageNum || 1;
      const api = this.$fetchApi.expertTalentList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200' && data.result) {
        this.total = data.result.total;
        this.list = data.result.list;
      }
    },
    // 页码变化
    async pageChange(page) {
      const expert_pageNum = page.page_index;
      this.$router.push({
        query: merge(this.$route.query, {
          expert_pageNum
        })
      });
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  components: { nfPagination }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 35px;
}
.example {
  font-size: 20px;
  padding-left: 20px;
  margin-top: 25px;
  border-left: 6px solid #265ae1;
}
.list {
  .item {
    overflow: hidden;
    .imgBox {
      float: left;
      width: 120px;
      height: 130px;
      margin-right: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      .name,
      .major {
        padding-bottom: 30px;
      }
      .name {
        font-weight: 900;
      }
    }
  }
}
.noData {
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 30px;
    color: #999;
    padding: 30px;
  }
}
</style>
